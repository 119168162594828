// Laraspace.scss - Bootstraps all the sass files into one

@import 'variables';

// Plugins
@import '../../plugins/bootstrap/scss/bootstrap';
@import '../../plugins/fontawesome/font-awesome';
@import '../../plugins/ladda/ladda.scss';
@import '../../plugins/customScrollBar/customScrollBar.scss';
@import '../../plugins/toastr/toastr.scss';
@import '../../plugins/notie/notie.scss';
@import "../../plugins/switchery/switchery.scss";
@import "../../plugins/select2/select2.scss";
@import "../../plugins/select2/select2-bootstrap.scss";
@import "../../plugins/bootstrapSelect/bootstrap-select.scss";
@import "../../plugins/clockpicker/clockpicker.scss";
@import "../../plugins/datatables/datatables.scss";
@import "../../plugins/multiselect/multiselect.scss";
@import "../../plugins/bootstrap-datepicker/bootstrap-datepicker.scss";
@import "../../plugins/timepicker/timepicker";
@import "../../plugins/summernote/summernote.scss";
@import "../../plugins/simplemde/simplemde.scss";
@import "../../plugins/pace/pace.scss";
@import "../../plugins/alertify/alertify.scss";
@import "../../plugins/easypiecharts/easypiecharts.scss";
@import "../../plugins/metisMenu/metisMenu.scss";

// Option Skin.
@import 'skins/skin-tyrell';
@import 'skins/skin-arryn';
@import 'skins/skin-targaryen';
@import 'skins/skin-lannister';
@import 'skins/skin-stark';

// Content Styles
@import 'base';

// Layouts
@import 'layouts/layout';
@import "layouts/layout-icon-sidebar";
@import "layouts/layout-horizontal";

//Partials
@import 'partials/header';
@import 'partials/sidebar';
@import 'partials/footer';

// Components
@import 'components/forms';
@import 'components/buttons';
@import 'components/cards';
@import 'components/tabs';
@import 'components/tables';
@import "components/modals";
@import 'components/hamburgers';
@import "components/transitions";

//Pages
@import 'pages/dashboard';
@import 'pages/login';
@import 'pages/user-profile';
@import 'pages/404';

@import "demo";

