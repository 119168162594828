// variables.scss - All Variables for Laraspace Admin Theme

/*
|--------------------------------------------------------------------------
| Primary Skin Colors
|--------------------------------------------------------------------------
*/

$ls-color-yellow: #ffde00;
$ls-color-black: #333;
$ls-color-black--light: #55595c;
$ls-color-blue: #007dcc;
$ls-color-green: #4fc47f;
$ls-color-red: #f35a3d;
$ls-color-gray--light: #f8f8f8;
$ls-color-gray--dark: #ddd;
$ls-color-gray--very-dark: #bababa;
$ls-color-light-blue: #5BBFDE;
$ls-color-orange: #f0ad4e;

/*
|--------------------------------------------------------------------------
| Bootstrap Overrides
|--------------------------------------------------------------------------
*/

$white: #fff;
$brand-primary:             $ls-color-blue !default;
$brand-secondary:           $ls-color-black !default;
$brand-success:             $ls-color-green !default;
$brand-info:                $ls-color-light-blue !default;
$brand-warning:             $ls-color-orange !default;
$brand-danger:              $ls-color-red !default;

$btn-secondary-color:            $white !default;
$btn-secondary-bg:               $ls-color-black !default;
$btn-secondary-border:           $ls-color-black !default;


/*
|--------------------------------------------------------------------------
| Current Theme Colors
|--------------------------------------------------------------------------
*/

$ls-color-primary : $ls-color-yellow;
$ls-color-secondary : $ls-color-black;

//HEADER SECTION
$header-bg-color: $ls-color-primary;
$header-font-color : $white;
$header-buttons-bg-color : $ls-color-black;
$header-buttons-bg-color--hover : lighten($ls-color-black,5%);
$header-buttons-font-color : $white;

//SIDEBAR SECTION
$sidebar-bg-color : $ls-color-secondary;
$sidebar-font-color : $white;
$sidemenu-item-bg-color--hover : lighten($ls-color-black,10%);
$sidemenu-item-bg-color--active : transparent;
$sidemenu-item-font-color : $white;
$sidemenu-item-font-color--active : $ls-color-primary;
$sidemenu-arrow-color : $ls-color-gray--very-dark;
$sidemenu-submenu-bg : lighten($ls-color-black,3%);

//DROPDOWN
$dropdown-bg: $ls-color-black;
$dropdown-link-color : $white;
$dropdown-border-color : $ls-color-black;
$dropdown-divider-bg : $ls-color-gray--very-dark;
$dropdown-header-color: $ls-color-primary;
$dropdown-header-border-color : $ls-color-gray--very-dark;

//Horizontal Menu
$header-bottom-bg : $ls-color-black;
$header-menu-item-font-color : darken($white,10%);
$header-menu-item-font-color--active : $white;
$header-menu-item-bg-color--hover : lighten($ls-color-black,10%);
$header-menu-item-bg-color--active : lighten($ls-color-black,10%);

//MISC
$code-bg : $ls-color-black;
$code-color : $ls-color-primary;

/*
|--------------------------------------------------------------------------
| Fonts
|--------------------------------------------------------------------------
*/

// -- Font Color
$base-font-family : 'Roboto',sans-serif;
$base-font-color : lighten($ls-color-black,15%);
$heading-font-color : $ls-color-black;
$font-family-base : $base-font-family;

// -- Sizes
$font-size-base:  1rem;
$font-size-section-title : 1.25rem;
$font-size-semi-section-title : 1.1rem;
$font-size-page-title : 1.5rem;


/*
|--------------------------------------------------------------------------
| Extra
|--------------------------------------------------------------------------
*/
$ls-login-bg-color-overlay: rgba(38 ,50 ,56,.6);

$headings-margin-bottom:     1.2rem;
$card-cap-bg:              #fff !default;

$pace-loader-color : darken($ls-color-primary,10%);